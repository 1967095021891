<template>
	<div v-if="pageShow">
		<my-dialog :title="title" :pageShow="pageShow" @close="close_dialog">
			<div class="content">
				<el-form :model="param" :rules="rules" ref="param" label-position="left" label-width="100px" class="demo-ruleForm">
					<!-- <el-form-item label="提现方式" prop="type">
						<el-select v-model="param.type" placeholder="请选择">
							<el-option label="支付宝" value="1"></el-option>
							<el-option label="微信" value="2"></el-option>
						</el-select>
					</el-form-item> -->
					<el-form-item label="账号" prop="t_zhifubao_number">
						<el-input v-model="param.t_zhifubao_number"></el-input>
					</el-form-item>
					<el-form-item label="姓名" prop="t_zhifubao_name">
						<el-input v-model="param.t_zhifubao_name"></el-input>
					</el-form-item>
					<el-form-item label="二维码" prop="t_zhifubao_qrCode">
						<div class="uploadImgBox x_start_v">
							<div class="img_box" v-if="param.t_zhifubao_qrCode">
								<img :src="param.t_zhifubao_qrCode" alt="">
								<div class="mask_box v_x_center">
									<i class="el-icon-delete" @click="deleImg"></i>
								</div>
							</div>
							<div class="file_box"  v-if="!param.t_zhifubao_qrCode">
								<input class="file_input" type="file" accept="image/png,image/jpeg,image/jpg,image/gif" @change="changeFile">
								<div class="icon_box v_x_center">
									<i v-if="!loadingIconShow" class="el-icon-plus"></i>
									<i v-if="loadingIconShow" class="el-icon-loading"></i>
								</div>
							</div>
							
						</div>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" :loading="loadingShow" @click="submitForm('param')">提交</el-button>
						<el-button @click="resetForm('param')">重置</el-button>
					</el-form-item>
				</el-form>
			</div>
		</my-dialog>
	</div>

</template>

<script>
	export default {
		props: {
			pageShow: {
				type: Boolean
			},
			title: {
				type: String
			}
		},
		data() {
			return {
				param: {
					type:"1",
					t_zhifubao_name:null,
					t_zhifubao_number:null,
					t_zhifubao_qrCode:null,
				},
				rules: {
					t_zhifubao_number: [{ required: true, message: '请输入提现账号', trigger: 'blur'}],
					t_zhifubao_name: [{ required: true, message: '请输入姓名', trigger: 'blur'}],
					t_zhifubao_qrCode: [{ required: true, message: '请选择二维码', trigger: 'blur'}],
				},
				file:null,
				loadingIconShow:false,
				loadingShow:false
			}
		},
		mounted() {

		},
		methods: {
			deleImg(){
				this.file = null
				this.param.t_zhifubao_qrCode = null
				this.loadingIconShow = false
			},
			submitForm(formName) {
				let that = this
				
				this.$refs[formName].validate((valid) => {
					if (valid) {
						that.save()
					} else {
						return false;
					}
				});
			},
			async save() {
				console.log("save")
				let that = this
				let param = {
					url: "updPutMoneyInfo",
					data: this.param
				}
				this.loadingShow = true
				await this.api(param).then(res => {
					that.list = []
					if (res.status == 0) {
						that.$message({
							message: '设置成功',
							type: 'success'
						});
						that.$emit("apply-success")
					}
				})
				this.loadingShow = false
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			close_dialog() {
				this.$emit("close")
			},
			changeFile(e){
				let file = e.target.files[0]
				this.saveFile(file)
				
			},
			async saveFile(file){
				let that = this
				let param = {
					url: "uploadFile",
					data: {file}
				}
				this.loadingIconShow = true
				await this.api(param).then(res => {
					if (res.status == 0) {
						that.param.t_zhifubao_qrCode = res.data
						// console.log("1111")
					}
				})
				this.loadingIconShow = false
			}
		}
	}
</script>

<style scoped="">
	.content {
		width: 500px;
		padding: 20px 40px !important;
		box-sizing: border-box;
	}
	
	.uploadImgBox>div{
		width: 130px;
		height: 130px;
		overflow: hidden;
	}
	.file_box{
		background-color: #fbfdff;
		border: 1px dashed #c0ccda;
		position: relative;
	}
	/* .file_box>.loaddingBox{
		position: absolute;
		left:0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
	} */
	.file_input{
		width: 130px;
		height: 130px;
		opacity: 0;
		position: absolute;
		left: 0;
		top:0;
	}
	.icon_box{
		width: 100%;
		height: 100%;
	}
	.img_box{
		position: relative;
		margin-right: 10px;
	}
	.img_box>img{
		width: 100%;
		height: 100%;
		
	}
	.img_box>.mask_box{
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top:0;
		background-color: rgba(0,0,0,0.6);
		color: white;
		z-index: -1;
	}
	.img_box:hover.img_box>.mask_box{
		z-index: 2;
	}
	.img_box>.mask_box>i{
		font-size: 20px;
		cursor: pointer;
	}
	
	
</style>
